// ========================= Header menu mobile =========================
document.addEventListener("DOMContentLoaded", function() {
   /* START ============= MENU MOBILE */
   const smdev_header_nav = document.querySelector('.smdev_header nav');
   const smdev_header_btn_burger = document.querySelector(".smdev_header_btn_burger");
   const smdev_header_btn_burger_div = document.querySelector(".smdev_header_btn_burger div");

   // Handle menu toggle

   function smdev_handle_menu_mobile(event) {
      if (event.cancelable) {
         event.preventDefault();
     }
      event.stopPropagation(); // Stop event propagation
      smdev_header_nav.classList.toggle("smdev_header_show");
      smdev_header_btn_burger_div.classList.toggle("smdev_close_icon");
      const isMenuOpen = smdev_header_nav.classList.contains("smdev_header_show");

      document.body.style.overflow = isMenuOpen ? "hidden" : "visible";
   }

   // Close menu if open and clicked outside of nav or sub-menu handling
   function closeMenuIfOpen(event) {
      const clickedElement = event.target;
      const hasSubMenu = clickedElement.nextElementSibling && clickedElement.nextElementSibling.tagName === "UL";
		
		if(hasSubMenu){
			clickedElement.nextElementSibling.classList.toggle("!flex");
         event.preventDefault();
		}

      if (!hasSubMenu && !smdev_header_nav.contains(clickedElement)) {
         if (smdev_header_nav.classList.contains("smdev_header_show")) {
            smdev_header_nav.classList.remove("smdev_header_show");
            smdev_header_btn_burger_div.classList.remove("smdev_close_icon");
            document.body.style.overflow = "visible";
         }
      }
   }

   // Listen for clicks and touch events
   smdev_header_btn_burger.addEventListener("click", smdev_handle_menu_mobile);
   smdev_header_btn_burger.addEventListener("touchstart", smdev_handle_menu_mobile);
   document.addEventListener("click", closeMenuIfOpen);
   /* END ============= MENU MOBILE */
});
// ========================= /Header menu mobile =========================


// ==================== REPRESENTANTES INTERACTIVE MAP ==================== //
document.addEventListener('DOMContentLoaded', () => {
   const stateMap = {
       "id-232": "AC", // Acre
       "id-162": "AL", // Alagoas
       "id-247": "AP", // Amapá
       "id-237": "AM", // Amazonas
       "id-147": "BA", // Bahia
       "id-172": "CE", // Ceará

       "id-207": "DF", // Distrito Federal
       "id-152": "ES", // Espírito Santo
       "id-202": "GO", // Goiás
       "id-192": "MA", // Maranhão
       "id-217": "MT", // Mato Grosso

       "id-212": "MS", // Mato Grosso do Sul
       "id-137": "MG", // Minas Gerais
       "id-222": "PA", // Pará
       "id-177": "PB", // Paraíba
       "id-127": "PR", // Paraná
       "id-167": "PE", // Pernambuco

       "id-187": "PI", // Piauí
       "id-142": "RJ", // Rio de Janeiro
       "id-182": "RN", // Rio Grande do Norte
       "id-117": "RS", // Rio Grande do Sul
       "id-227": "RO", // Rondônia

       "id-242": "RR", // Roraima
       "id-122": "SC", // Santa Catarina
       "id-132": "SP", // São Paulo
       "id-157": "SE", // Sergipe
       "id-197": "TO" // Tocantins
   };

   const attachClickListeners = () => {
       const stateElements = document.querySelectorAll('g[id^="id-"]'); // Select all <g> elements with id starting with "id-"
       stateElements.forEach((element) => {
           const stateId = element.getAttribute('id');
           if (stateMap[stateId]) {
               // Attach click event listener
               element.addEventListener('click', () => {
                   const stateAbbreviation = stateMap[stateId];

                   // Hide all representante cards
                   const allCards = document.querySelectorAll('.representante-card'); // Make sure to add this class to all your cards
                   allCards.forEach(card => {
                       card.classList.add('hidden'); // Add hidden class to all cards
                   });

                   // Show cards that match the clicked state
                   const visibleCards = document.querySelectorAll(`.estado-${stateAbbreviation}`);
                   if (visibleCards.length > 0) {
                       visibleCards.forEach(card => {
                           card.classList.remove('hidden'); // Remove hidden class from matching cards
                       });
                       document.getElementById('nadaEncontrado').classList.add('hidden'); // Hide the "Nenhum representante encontrado" message
                   } else {
                       // Show "Nenhum representante encontrado" message if no cards are visible
                       document.getElementById('estadoAtual').textContent = stateAbbreviation; // Update state abbreviation in message
                       document.getElementById('nadaEncontrado').classList.remove('hidden'); // Show the message
                   }
               });

           } else {
               console.warn(`No match found for ID ${stateId}`);
           }
       });
   };

   const observer = new MutationObserver(() => {
       attachClickListeners();
   });

   observer.observe(document.body, {
       childList: true,
       subtree: true
   });

   attachClickListeners();
});
// ==================== / REPRESENTANTES INTERACTIVE MAP ==================== //